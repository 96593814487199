<script>
  import Message from './Message.svelte'
  import { onMount } from 'svelte'
  import {
    parseDate,
    parseMode,
    parsePlatform,
    getCurrentDateFormatted,
    addZeroBefore,
  } from './helpers'
  import Clock from "./Clock.svelte";

  export let wantedStation

  let data = {}

  const request = async () => {
    const now = new Date()
    const res = await fetch(
      `https://api.irail.be/liveboard/?station=${wantedStation}&fast=true&format=json&time=${addZeroBefore(now.getHours())}${addZeroBefore(now.getMinutes())}&date=${getCurrentDateFormatted()}&lang=en`
    )
    const resJson = await res.json()

    let results = []
    results = resJson.departures.departure.map((d) => {
      const departureTime = parseDate(d.time)
      const arrivalTime = parseDate(d.time)
      const scheduledTime = parseDate(d.time)
      return {
        departureTime,
        scheduledTime,
        id: d.id,
        line: d.platform,
        direction: d.station,
        platform: parsePlatform(d.platform),
        mode: parseMode(d.vehicleinfo.type),
        canceled: d.canceled,
        delay: d.delay,
      }
    });

    data.name = resJson.stationinfo.standardname
    data.departures = results
  }

  onMount(() => {
    request() // first request
    const interval = setInterval(request, 30000) // request every 30 seconds

    return () => {
      clearInterval(interval)
    }
  })
</script>

<div class="flex-1 w-1/2" data-id={`stop-${wantedStation}`}>
  {#if data && data.name}
    <div class="">
      <div class="py-4 px-2 relative flex items-center justify-between">
        <svg style="enable-background:new 0 0 504 329.2" version="1.1" viewBox="0 0 504 329" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 text-primary">
          <style type="text/css">.st0{fill:#0071B7;}</style>
            <path class="st0" d="m252 312c-115 0-209-66.2-209-147s93.7-147 209-147 209 66.2 209 147-93.7 147-209 147m0-312c-139 0-252 73.2-252 165s113 165 252 165 252-74 252-165-113-165-252-165"/>
            <path class="st0" d="m263 251h-27.6c-8.7 0-13.4-3.9-13.4-11v-63c0-3.9 1.6-5.5 5.5-5.5h35.5c22.3 0 40.5 17.9 40.9 40.2 0.3 21.5-16.9 39.1-38.4 39.4-0.8 0-1.6 0-2.5-0.1m-40.9-161c0-7.1 4.7-11 13.4-11h18.1c18.7-0.8 34.6 13.7 35.4 32.4v1.5c-0.3 19.3-16.1 34.8-35.4 34.6h-26c-3.9 0-5.5-1.6-5.5-5.5v-52zm111 70.1c-5.5-2.4-5.5-3.2 0-6.3 14.1-8.7 22.5-24.3 22-40.9 0-30.7-40.9-61.4-106-61.4-37.8-0.2-74.5 12-105 34.6-5.5 4.7-4.7 7.1-3.1 8.7l9.5 11c3.1 3.2 4.7 2.4 6.3 0.8 7.1-5.5 7.9-2.4 7.9 3.9v109c0 6.3-0.8 9.5-7.9 3.9-1.6-1.6-3.1-2.4-6.3 0.8l-10.2 11.8c-1.6 2.4-3.1 4.7 3.2 8.7 31.6 21.6 68.8 33.4 107 33.9 73.2 0 119-30.7 119-71.7 0.6-27.6-22.2-41-36.4-46.5"/>
        </svg>
        <span class="ml-2 text-6xl">{data.name}</span>
        <Clock />
      </div>

      <div class="flex flex-col w-full h-full whitespace-nowrap">
        <div class="flex flex-row h-12 text-xl font-bold">
          <div
            class="w-16 flex-shrink-0 flex-grow-0 p-2 bg-black border-2 border-black align-middle text-left"
          />
          <div
            class="w-26 flex-shrink-0 flex-grow-0 p-2 bg-black border-2 border-black align-middle text-left"
          >
            Platform
          </div>
          <div
            class="flex-grow p-2 bg-black border-2 border-black align-middle text-left"
          >
            To
          </div>
          <div
            class="w-60 flex-shrink-0 flex-grow-0 p-2 bg-black border-2 border-black align-middle text-right"
          >
            Departure Time
          </div>
        </div>
        {#each data.departures as { id, line, direction, departureTime, mode, delay, canceled }}
          <div
            class="flex flex-row h-16 text-4xl text-primary-light"
            data-id={id}
          >
            <div
              class="w-16 flex-shrink-0 flex-grow-0 p-2 bg-secondary border-2 border-black align-middle text-center"
            >
              <span class="text-white">{mode.name}</span>
            </div>
            <div
              class="w-24 flex-shrink-0 flex-grow-0 p-2 bg-secondary border-2 border-black align-middle text-center text-white"
            >
              {line}
            </div>
            <div
              class="flex-grow p-2 bg-secondary border-2 border-black align-middle text-left overflow-hidden overflow-ellipsis text-white"
            >
              {direction}
            </div>
            <div
              class="w-60 flex-shrink-0 flex-grow-0 p-2 bg-secondary border-2 border-black align-middle text-right text-white"
            >
              {#if canceled !== '0'}
                <span class="text-red">Canceled</span>
                {:else}
                {#if delay !== '0'}<span class="text-red">+ {delay / 60}</span>{/if} {departureTime}
              {/if}
            </div>
          </div>
        {:else}
          <div class="table-row">
            <div class="table-cell">No departures available</div>
          </div>
        {/each}
      </div>
    </div>
  {:else}
    <Message
      title="Something went wrong!"
      text="We encountered an issue and are trying our best to resolve the problem as soon as possible."
    />
  {/if}
</div>
