export function parseDate(d) {
  const date = new Date(d * 1000); // Convert seconds to milliseconds

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

export function dateDifference(start, end) {
  return Math.round((end.getTime() - start.getTime()) / 1000 / 60)
}

export function parsePlatform(p) {
  return p ? { name: p.Name, type: p.Type } : undefined
}

export function getCurrentDateFormatted() {
  const currentDate = new Date();

  // Get day, month, and year components
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = currentDate.getFullYear().toString().slice(-2);

  // Concatenate components in the desired format
  const formattedDate = day + month + year;

  return formattedDate;
}

export function addZeroBefore(n) {
  return (n < 10 ? '0' : '') + n;
}

export const MODES = {
  Unknown: {
    title: 'Unknown',
    name: 'Unknown',
  },
  S: {
    title: 'S',
    name: 'S',
  },
  L: {
    title: 'L',
    name: 'L',
  },
  P: {
    title: 'P',
    name: 'P',
  },
  IC: {
    title: 'IC',
    name: 'IC',
  },
}

export function parseMode(name) {
  if (!name) {
    return undefined
  }

  switch (name.toLowerCase()) {
    case 's':
    case 's1':
    case 's2':
    case 's3':
    case 's4':
    case 's5':
    case 's6':
    case 's7':
    case 's8':
    case 's81':
    case 's9':
    case 's10':
    case 's20':
      return MODES.S
    case 'l':
      return MODES.L
    case 'p':
      return MODES.P
    case 'ic':
      return MODES.IC
    default:
      return MODES.Unknown
  }
}
